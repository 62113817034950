<template>
  <div :class="['menuContainer', route]">
    <ul
      ref="menuList"
      class=""
    >
      <NavHeader class="nav" />
      <h2>SITIO DE INICIO</h2>
      <li :class="homeClass">
        <Button
          model="menuItem"
          size="xl"
          group="menuGroup"
          on
          @click.native="goTo(home)"
        >
          <FlagIcon
            slot="defaultPre"
          />
          Home
        </Button>
      </li>
      <hr>
      <h2>¿QUÉ ESTAS BUSCANDO?</h2>
      <li :class="propertiesClass">
        <Button
          model="menuItem"
          size="xl"
          group="menuGroup"
          @click.native="goTo(properties)"
        >
          <HouseIcon
            slot="defaultPre"
          />
          Residencias
        </Button>
      </li>
      <li :class="developmentsClass">
        <Button
          model="menuItem"
          size="xl"
          group="menuGroup"
          @click.native="goTo(developments)"
        >
          <ApartmentIcon
            slot="defaultPre"
          />
          Emprendimientos
        </Button>
      </li>
      <!--      <li :class="valuationClass">-->
      <!--        <Button-->
      <!--          model="menuItem"-->
      <!--          size="xl"-->
      <!--          group="menuGroup"-->
      <!--          @click.native="goTo(valuation)">-->
      <!--          <ValuationIcon-->
      <!--            slot="defaultPre"-->
      <!--          />-->
      <!--          Tasaciones-->
      <!--        </Button>-->
      <!--      </li>-->
      <hr>
      <!--      <h2>MÁS INFORMACIÓN</h2>-->

      <li :class="faqClass">
        <!--        <Button-->
        <!--          model="menuItem"-->
        <!--          size="xl"-->
        <!--          group="menuGroup"-->
        <!--          @click.native="goTo(faq)"-->
        <!--        >-->
        <!--          <HelpIcon-->
        <!--            slot="defaultPre"-->
        <!--          />-->
        <!--          Preguntas Frecuentes-->
        <!--        </Button>-->
      </li>
      <!--      <li :class="contactClass">-->
      <!--        <Button-->
      <!--          model="menuItem"-->
      <!--          size="xl"-->
      <!--          group="menuGroup"-->
      <!--          @click.native="goTo(contact)"-->
      <!--        >-->
      <!--          <ContactIcon-->
      <!--            slot="defaultPre"-->
      <!--          />-->
      <!--          Contacto-->
      <!--        </Button>-->
      <!--      </li>-->
    </ul>
  </div>
</template>

<script>

// icons
import HouseIcon from '@/icons/House'
import FlagIcon from '@/icons/Flag'
import ComercialIcon from '@/icons/Comercial'
import AboutIcon from '@/icons/About'
import HelpIcon from '@/icons/Help'
import ContactIcon from '@/icons/Contact'
import UserIcon from '@/icons/User'
import ValuationIcon from '@/icons/Valuation'
import ApartmentIcon from '@/icons/Apartment'
// builders
import Button from '@/build/Button'
import Bus from '@/utils/Bus'
// widgets
import NavHeader from '@/widgets/NavHeader'

export default {
  name: 'Menu',
  components: {
    Button,
    HouseIcon,
    FlagIcon,

    // HelpIcon,
    // ContactIcon,
    // ValuationIcon,
    ApartmentIcon,
    NavHeader
  },
  data () {
    return {
      home: '/',
      comercial: '/properties/%7B%22view%22:true,%22operation%22:%22%22,%22propTypes%22:[8,7,12,5],%22rooms%22:99,%22order%22:%22%22,%22zones%22:[],%22scope%22:[],%22item%22:0%7D',
      valuation: '/valuation',
      developments: '/developments/list',
      properties: '/properties',
      contact: '/contact',
      faq: '/faq'
    }
  },
  computed: {
    homeClass () {
      return (this.$route.name === 'Home') ? 'active' : ''
    },
    propertiesClass () {
      return (this.$route.name === 'Properties' ||
        this.$route.name === 'PropertiesFilter') ? 'active' : ''
    },
    developmentsClass () {
      return (this.$route.name === 'Developments' ||
        this.$route.name === 'DevelopmentsFilter') ? 'active' : ''
    },
    valuationClass () {
      return (this.$route.name === 'Valuation') ? 'active' : ''
    },
    faqClass () {
      return (this.$route.name === 'Faq') ? 'active' : ''
    },
    contactClass () {
      return (this.$route.name === 'Contact') ? 'active' : ''
    },
    // filter () {
    //   return JSON.stringify(this.$store.getters.filter)
    // },
    isMobile () {
      return !(document.body.getBoundingClientRect().width < 640)
    },
    route () {
      return (this.$route.name === 'Development' ||
        this.$route.name === 'Developments' ||
        this.$route.name === 'DevelopmentsFilter') ? 'dev' : ''
    }
  },
  methods: {
    goTo (view, gtmId) {
      // this.$gtm.trackEvent({
      //   event: null, // Event type [default = 'interaction'] (Optional)
      //   category: 'Calculator',
      //   action: 'click',
      //   label: 'Home page SIP calculator',
      //   value: 5000,
      //   noninteraction: false, // Optional
      //   id: `${view}`
      // })

      if (!this.isMobile) {
        this.$router.push(view)
      } else if (view === this.valuation || view === this.faq) {
        const routeData = this.$router.resolve({ path: `${view}` })
        window.open(routeData.href, '_blank')
      } else {
        this.$router.push(view)
      }

      Bus.$emit('showMenu')
      Bus.$emit('hamburguerReset')

      // this.$router.push({ path: view, query: { reload: true } })
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/assets/scss/global";

.menuContainer{
  position: absolute;
  display: block;
  right:0;
  top:0;
  width: 100%;
  max-width: 30em;
  height: 100%;
  z-index: 85;
  background-color: var(--color02);
  @include fromTo(s,l){
    padding-top: 0em;
    height: calc(100% - 3em);
    overflow-y: scroll;
  }
  &.dev{
    background-color: var(--main)
  }
}

.nav{
  padding-top: 0.25em;
  width: 100%;
  @include flex;
  justify-content: flex-end;
  @include from(xl){
    // justify-content: center;
  }
  @include from(xxl){
    justify-content: center;
  }
}

ul{
  display: block;
  width: 100%;
  padding: 0em 0.6em;
  @include from(l){
    padding: 0.2em 0 0.2em 0.6em;
  }
  h2{
    color: var(--color13);
    font-size: 0.8em;
    letter-spacing: 1px;
    font-family: $Work;
    font-weight: 600;
    padding-left: $gutter;
    margin: 1.5em 0 0.6em 2.5em;
  }
  button{
    font-family: $Pala;
    font-weight: 700;
    align-items: flex-end;

    font-size: 1.4em;
    padding:0.2em 0;
    @include from(m){
      font-size: 1.5em;
    }
    .dev &{
      color: green;
    }
    .icon{
      font-size: 1.4em;
      height: 0.95em;
      color: var(--color11);
    }
  }
  li.active{
    button{
      color: var(--color08);
    }
  }
}
hr{
  border: none;
  border: 1px solid var(--color04);
  margin-top: 1em;
  margin-right: 0.6em;
  .dev &{
    border-color: var(--color06);
    opacity: 0.25;
  }
}

.fadeLeft-enter-active, .fadeLeft-leave-active {
  transform: translate(0, 0);
  transition: all .5s;
  opacity: 1;
}
.fadeLeft-enter, .fadeLeft-leave-to{
  opacity: 0;
  transform: translate(100%, 0);
  transition: all .5s;
}

</style>
