import Vue from "vue";
import VueRouter from "vue-router";

const Home = () => import("@/views/Home.vue");
const Properties = () => import("@/views/Properties.vue");
const CardLargeProp = () => import("@/views/CardLargeProp.vue");
const Developments = () => import("@/views/Developments.vue");
const CardLargeDev = () => import("@/views/CardLargeDev.vue");
const Valuation = () => import("@/views/Valuation.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { gtm: "Home" },
  },
  {
    path: "/properties",
    name: "Properties",
    alias: ["/Propiedades", "/p"],
    component: Properties,
    children: [
      {
        path: "/properties/:filter",
        name: "PropertiesFilter",
        component: Properties,
      },
    ],
    meta: { gtm: "Propiedades" },
  },
  {
    path: "/p/:id",
    name: "Property",
    component: CardLargeProp,
    children: [
      {
        path: "/p/:id/:q",
        name: "PropertiesFilterQuery",
        component: CardLargeProp,
      },
    ],
  },
  {
    path: "/developments",
    name: "Developments",
    alias: "/Emprendimientos",
    component: Developments,
    children: [
      {
        path: "/developments/:filter",
        name: "DevelopmentsFilter",
        component: Developments,
      },
    ],
    meta: { gtm: "Desarrollos" },
  },
  {
    path: "/d/:id",
    name: "Development",
    component: CardLargeDev,
    children: [
      {
        path: "/d/:id/:q",
        name: "DevelopmentsFilterQuery",
        component: CardLargeDev,
      },
    ],
  },
  {
    path: "/valuation",
    name: "Valuation",
    alias: "/Tasaciones",
    component: Valuation,
    meta: { gtm: "Tasaciones" },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (from.name !== to.name) return { x: 0, y: 0 };
  },
});

export default router;
