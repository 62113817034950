<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      :stroke="strokeColor"
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="help"
        clip-path="url(#clip-help)"
      >
        <g
          id="Trazado_880"
          data-name="Trazado 880"
          transform="translate(2.064 2.064)"
          fill="none"
        >
          <path
            d="M9.936,0A9.936,9.936,0,1,1,0,9.936,9.936,9.936,0,0,1,9.936,0Z"
            stroke="none"
          />
          <path
            d="M 9.935546875 1.149997711181641 C 5.091176986694336 1.149997711181641 1.149997711181641 5.091176986694336 1.149997711181641 9.935546875 C 1.149997711181641 14.77991676330566 5.091176986694336 18.72109603881836 9.935546875 18.72109603881836 C 14.77991676330566 18.72109603881836 18.72109603881836 14.77991676330566 18.72109603881836 9.935546875 C 18.72109603881836 5.091176986694336 14.77991676330566 1.149997711181641 9.935546875 1.149997711181641 M 9.935546875 -3.814697265625e-06 C 15.42279624938965 -3.814697265625e-06 19.87109756469727 4.448296546936035 19.87109756469727 9.935546875 C 19.87109756469727 15.42279624938965 15.42279624938965 19.87109756469727 9.935546875 19.87109756469727 C 4.448296546936035 19.87109756469727 -3.814697265625e-06 15.42279624938965 -3.814697265625e-06 9.935546875 C -3.814697265625e-06 4.448296546936035 4.448296546936035 -3.814697265625e-06 9.935546875 -3.814697265625e-06 Z"
            stroke="none"
            fill="#e00009"
          />
        </g>
        <g
          id="Grupo_868"
          data-name="Grupo 868"
          transform="translate(8.452 6.323)"
        >
          <path
            id="Trazado_879"
            data-name="Trazado 879"
            d="M1492.859,2858.172l-.872.609a.678.678,0,0,1-.954-.148.691.691,0,0,1,.28-.937l.9-.625a2.378,2.378,0,0,0,1.316-2.072,1.757,1.757,0,0,0-1.892-1.76c-1.629,0-1.974,1.316-2.615,1.316a.552.552,0,0,1-.592-.592c0-.724,1.283-2.089,3.29-2.089a3.035,3.035,0,0,1,3.273,3.043C1495,2856.165,1494.471,2857.1,1492.859,2858.172Zm-.164,3.586a1.02,1.02,0,0,1-2.04,0,1.02,1.02,0,1,1,2.04,0Z"
            transform="translate(-1488.435 -2851.872)"
            fill="#e00009"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    strokeColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
