<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      :fill="fillColor"
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <path d="M19.63,4.33A10.72,10.72,0,0,0,2.76,17.26L1.24,22.81l5.68-1.49A10.76,10.76,0,0,0,12,22.62h0A10.71,10.71,0,0,0,19.62,4.33ZM12.05,20.81h0a8.73,8.73,0,0,1-4.54-1.24l-.33-.19-3.37.88L4.71,17l-.21-.34a8.91,8.91,0,1,1,7.55,4.17Zm4.88-6.66c-.27-.14-1.58-.78-1.83-.87s-.42-.14-.6.13-.69.87-.85,1.05-.31.2-.58.07a7.44,7.44,0,0,1-2.15-1.33,7.87,7.87,0,0,1-1.49-1.86c-.16-.27,0-.41.12-.54s.26-.32.4-.47a1.7,1.7,0,0,0,.26-.45.47.47,0,0,0,0-.47c-.06-.13-.6-1.45-.82-2S8.93,7,8.77,7H8.25a.94.94,0,0,0-.71.33A3,3,0,0,0,6.6,9.52a5.29,5.29,0,0,0,1.09,2.77,12,12,0,0,0,4.58,4,13.18,13.18,0,0,0,1.52.57,3.72,3.72,0,0,0,1.69.1,2.75,2.75,0,0,0,1.81-1.27,2.21,2.21,0,0,0,.15-1.27C17.38,14.35,17.2,14.28,16.93,14.15Z" />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Whatsapp'
    },
    fillColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
