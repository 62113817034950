a<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      :stroke="strokeColor"
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <g transform="translate(0 -1.014)">
          <g transform="translate(5 4.014)">
            <path
              d="M1927.817,2292.072l-.64-.367a.64.64,0,0,1-.323-.556v-16.226a.642.642,0,0,1,.642-.642h1.283v16.868a.641.641,0,0,1-.323.556l-.64.367"
              transform="translate(-1926.854 -2274.28)"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.15"
            />
          </g>
          <path
            d="M1952.8,2279.4h-3.213v8.023h-3.691v.642a.642.642,0,0,0,.642.642h6.26a.642.642,0,0,0,.593-.888l-1.557-3.754,1.56-3.779A.642.642,0,0,0,1952.8,2279.4Z"
            transform="translate(-1934.784 -2273.743)"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.15"
          />
          <path
            d="M1940.758,2276.683v-.962a.321.321,0,0,0-.321-.321H1932.9v9.307h4.172l3.367.029a.32.32,0,0,0,.323-.321Z"
            transform="translate(-1925.956 -2271.027)"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.15"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    strokeColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
