<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      :stroke="strokeColor"
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <g transform="translate(-874.614 -2541.111)">
          <g transform="translate(878.299 2545.111)">
            <path
              d="M889.157,2551.28h-1.374a.886.886,0,0,0-.886.886v1.63"
              transform="translate(-885.271 -2549.457)"
              fill="none"
              stroke-miterlimit="10"
              stroke-width="1.15"
            />
            <path
              d="M894.546,2551.28"
              transform="translate(-890.661 -2549.457)"
              fill="none"
              stroke-miterlimit="10"
              stroke-width="1.15"
            />
            <path
              d="M928.572,2553.8v-1.63a.886.886,0,0,0-.886-.886h-1.347"
              transform="translate(-913.059 -2549.457)"
              fill="none"
              stroke-miterlimit="10"
              stroke-width="1.15"
            />
            <path
              d="M885.9,2575.1v6.092a.886.886,0,0,0,.886.886h12.41a.886.886,0,0,0,.886-.886v-6.056"
              transform="translate(-884.567 -2566.241)"
              fill="none"
              stroke-miterlimit="10"
              stroke-width="1.15"
            />
            <line
              x1="2.13"
              transform="translate(14.773 15.833)"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.15"
            />
            <line
              x1="2.13"
              transform="translate(0 15.833)"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.15"
            />
            <path
              d="M1,0H5.451a1,1,0,0,1,1,1V4.9a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1A1,1,0,0,1,1,0Z"
              transform="translate(5.196 10.932)"
              fill="none"
              stroke-miterlimit="10"
              stroke-width="1.15"
            />
            <rect
              width="9.406"
              height="4.012"
              rx="1"
              transform="translate(3.718)"
              fill="none"
              stroke-miterlimit="10"
              stroke-width="1.15"
            />
          </g>
          <g transform="translate(877 2549.362)">
            <path
              d="M933.625,2564.523h0a1.625,1.625,0,0,0,1.625-1.625l-.476-2.739a.8.8,0,0,0-.783-.658H932v3.4A1.625,1.625,0,0,0,933.625,2564.523Z"
              transform="translate(-915.749 -2559.5)"
              fill="none"
              stroke-miterlimit="10"
              stroke-width="1.15"
            />
            <path
              d="M0,0H3.25a0,0,0,0,1,0,0V3.4A1.625,1.625,0,0,1,1.625,5.023h0A1.625,1.625,0,0,1,0,3.4V0A0,0,0,0,1,0,0Z"
              transform="translate(13.001 0)"
              fill="none"
              stroke-miterlimit="10"
              stroke-width="1.15"
            />
            <path
              d="M0,0H3.25a0,0,0,0,1,0,0V3.4A1.625,1.625,0,0,1,1.625,5.023h0A1.625,1.625,0,0,1,0,3.4V0A0,0,0,0,1,0,0Z"
              transform="translate(9.751 0)"
              fill="none"
              stroke-miterlimit="10"
              stroke-width="1.15"
            />
            <path
              d="M0,0H3.25a0,0,0,0,1,0,0V3.4A1.625,1.625,0,0,1,1.625,5.023h0A1.625,1.625,0,0,1,0,3.4V0A0,0,0,0,1,0,0Z"
              transform="translate(6.5 0)"
              fill="none"
              stroke-miterlimit="10"
              stroke-width="1.15"
            />
            <path
              d="M0,0H3.25a0,0,0,0,1,0,0V3.4A1.625,1.625,0,0,1,1.625,5.023h0A1.625,1.625,0,0,1,0,3.4V0A0,0,0,0,1,0,0Z"
              transform="translate(3.25 0)"
              fill="none"
              stroke-miterlimit="10"
              stroke-width="1.15"
            />
            <path
              d="M878.625,2564.523h0a1.625,1.625,0,0,0,1.625-1.625v-3.4H878.26a.8.8,0,0,0-.783.658L877,2562.9A1.625,1.625,0,0,0,878.625,2564.523Z"
              transform="translate(-877 -2559.5)"
              fill="none"
              stroke-miterlimit="10"
              stroke-width="1.15"
            />
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    strokeColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
