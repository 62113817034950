<template>
  <button
    :class="['btn', model, size, group, stateClass]"
    :style="{margin: margin}"
    @click="toggle"
  >
    <slot
      v-if="!dualActive"
      name="defaultPre"
    />
    <slot
      v-if="dualActive"
      name="activePre"
    />
    {{ value }}
    <slot
      v-if="!dualActive"
      name="default"
    />
    <slot
      v-if="dualActive"
      name="active"
    />
  </button>
</template>

<script>
import Bus from '@/utils/Bus'

export default {
  name: 'Button',
  props: {
    name: String,
    value: String,
    size: String,
    group: String,
    swich: Boolean,
    boolean: Boolean,
    dual: Boolean,
    model: String,
    margin: String,
    emmit: String,
    on: Boolean,
    extraClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      active: false,
      dualActive: false
    }
  },
  computed: {
    stateClass () {
      if (!this.swich && !this.boolean) return ''
      return (this.active) ? (this.model + 'Active' + this.extraClass)
        : (this.model + 'Default' + this.extraClass)
    },
    statePro () {
      return this.on
    }
  },
  watch: {
    statePro (n, o) {
      if (n) {
        this.active = true
      } else {
        this.active = false
      }
      if (this.$props.dual) {
        if (n) {
          this.dualActive = true
        } else {
          this.dualActive = false
        }
      }
    }
  },
  created () {
    Bus.$on(this.$props.group, this.reset)
  },
  mounted () {
    Bus.$on(this.name + 'Reset', this.reset)
    Bus.$on('reset', this.reset)
    if (this.on) {
      this.active = true
      if (this.$props.dual) {
        this.dualActive = true
      }
    }
  },
  methods: {
    toggle () {
      if (this.$props.dual) {
        this.dualActive = !this.dualActive
      }
      if (!this.boolean) return
      if (this.$props.emmit) {
        Bus.$emit(this.$props.emmit)
      }
      if (this.$props.group) {
        Bus.$emit(this.$props.group)
        this.active = true
      }
      if (this.$props.swich && !this.$props.group) {
        this.active = !this.active
      }
    },
    reset () {
      this.active = false
      this.dualActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/global";

@keyframes popup {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.popUp {
  animation: popup 0.25s ease-out;
}

// -----base------ //
.btn {
  @include flex;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  line-height: 1em;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all ease-in-out 0.15s;

  span {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  svg {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  a {
    color: inherit;
    display: inherit;
    align-items: center;
  }
}

// -----size------ //
.full {
  width: 100%;
}

.mid {
  width: calc(50% - 0.5em);
}

// Especiales
.mainL {
  font-size: 1.35em;
  padding: $gutter;
}

.mainM {
  font-size: 1.05em;
  padding: $gutter;
}

.mainS {
  font-size: 0.85em;
  padding: $gutter;
}

//Standar para combinandos
.lt {
  font-size: 1.35em;
  padding: 1em;
}

.mt {
  padding: 1em;
  font-size: 1em;
}

.st {
  padding: 1em;
  font-size: 0.8em;
}

//Standar recomendado para icosnos
.xl {
  font-size: 1.5em;
  padding: 0.5em;
}

.lFlat {
  font-size: 1.35em;
  padding: 0 0.5em;
}

.l {
  font-size: 1.35em;
  padding: 0.5em;
}

.m {
  padding: 0.5em;
  font-size: 1.2em;
}

.s {
  padding: 0.25em;
  font-size: 1em;
}

.i {
  font-size: 1.5em;
  padding: 0.25em;
}

// -----modelos------ //

// btn acent

.acent {
  background-color: var(--white);
  color: var(--color00);
  border-radius: 50%;
  @include softShadow;
}

//SelectCh

.selectCh {
  top: 0;
  right: 0;
  border-left: 1px solid var(--color06);
  border-bottom: 1px solid var(--color06);
  border-radius: 0.75em;
  margin-bottom: -1px;
  // background: var(--color01);
  color: var(--color06);
}

// Like
.likeDisplay {
  position: absolute;
  @include fromTo(s, m) {
    border-top-left-radius: 0.5em;
  }
  @include from(m) {
    border-bottom-left-radius: 0.5em;
  }
}

// propiedas en defoult
.likeDisplayDefault {
  background-color: var(--color00);
  color: var(--main);
}

// propiedades en active
.likeDisplayActive {
  background-color: var(--white);
  color: var(--color00);
}

// propiedas en defoult
.likeDisplayDefaultDark {
  background-color: var(--main);
  color: var(--color04);
}

// propiedades en active
.likeDisplayActiveDark {
  background-color: var(--white);
  color: var(--color00);
}

//-----//
// Development
.goToDevelopment {
  justify-content: flex-start;
  align-items: center;
  background-color: var(--main);
  color: var(--white);
  @include borderRadius;
  @include softShadow;
  font-family: $Work;
  font-weight: 500;
  text-align: left;
  padding-left: 0;
  padding-right: 0;

  .dev {
    @include mainCenter;
    width: 3.8em;

    .icon {
      font-size: 2.1em;
    }
  }

  .arrow {
    @include mainCenter;
    width: 2em;

    .icon {
      font-size: 1.5em;
    }
  }

  main {
    @include mainStart;
    width: calc(100% - 5.6em);

    small {
      width: 100%;
      padding-bottom: 0.2em;

    }
  }

  .arrow {
  }
}

// propiedas en defoult
.goToDevelopmentDefault {
  background-color: var(--color00);
  color: var(--white);

  main {
    color: var(--color09);
  }

  .arrow {
    border-left: 1px solid var(--color03);
    height: 2.2em;
  }
}

// propiedades en active
.goToDevelopmentActive {
  background-color: var(--white);
  color: var(--color00);
}

// propiedas en defoult
.goToDevelopmentDefaultDark {
  background-color: var(--main);
  color: var(--color04);
}

// propiedades en active
.goToDevelopmentActiveDark {
  background-color: var(--white);
  color: var(--color00);
}

//-----//
// MenuItem
.menuItem {
  color: var(--color06);

  .dev & {

    color: var(--color04);
  }

  .icon {
    padding-right: 1.3em;
  }

  &:hover {
    color: var(--color11);
  }
}

// propiedas en defoult
.menuItemDefault {
  color: var(--color13);

  &:hover {
    color: var(--white);
  }
}

// propiedas en defoult
.menuItemFooter {
  padding-left: 0;
  color: var(--color05);

  &:hover {
    color: var(--color05);
  }

  .icon {
    color: var(--white);
    @include from(l) {
      padding-right: 1.5em;
    }
  }

  .arrow, p {
    display: none;
  }

  @include from(l) {
    .arrow, p {
      display: flex;
    }
  }
}

// propiedades en active
.menuItemActive {
  color: var(--white);
}

// propiedas en defoult
.menuItemHeader {
  @include flex;
  color: var(--white);
  align-items: flex-start;
  flex-direction: column;

  p {
    font-size: 0.68em;
    margin-top: 0.25em;
    font-weight: 400;
    display: none;
    @include from(l) {
      display: flex;
    }
  }
}

.btnLineW, .btnLineWDark {
  justify-content: center;
  align-items: center;
  @include borderRadius;
  font-family: $Work;
  font-weight: 500;
  letter-spacing: 0.025em;
  text-align: left;

  .icon {
    margin: 0 0.6em 0 0;
    align-self: flex-start;
    font-size: 1.25em;
  }
}

.btnLineW {
  background-color: #5b6662;
  color: var(--white);
  @include softShadow;

  &:hover {
    @include gradient-vertical(#404846, #7c8c86);
    @include mediumShadow;
  }
}

.btnLineWDark {
  background-color: var(--color05);
  color: var(--main);
}

.btnLineG {
  justify-content: center;
  background-color: var(--color03);
  color: var(--color01);
  align-items: center;
  @include borderRadius;
  // @include softShadow;
  font-family: $Work;
  font-weight: 500;
  letter-spacing: 0.025em;
  text-align: left;

  .icon {
    margin: 0 0.6em 0 0;
    align-self: flex-start;
    font-size: 1.25em;
  }

  &:hover {
    // @include mediumShadow;
  }
}

.btnLineDev {
  justify-content: center;
  background-color: var(--white);
  color: var(--color00);
  align-items: center;
  @include borderRadius;
  // @include softShadow;
  font-family: $Work;
  font-weight: 400;
  letter-spacing: 0.025em;
  text-align: left;
  border: 1px solid var(--color06);

  .icon {
    margin: 0 0.6em 0 0;
    align-self: flex-start;
    font-size: 1.25em;
  }

  &:hover {
    background-color: var(--color09);
    border-color: var(--color06);
    @include softShadow;
  }
}

.btnLineWPlus {
  @include mainCenter;
  align-items: center;
  background-color: #fff;
  color: var(--white);
  @include borderRadius;
  @include softShadow;
  font-family: $Work;
  font-weight: 500;
  text-align: left;

  .icon {
    @include mainCenter;
    align-items: center;
    font-size: 1.25em;
    max-height: 0.75em;
    margin: 0 0.5em 0 0;
    align-self: flex-start;
  }
}

.btnLineWActive {
  color: #fff;
  animation: popup 0.25s ease-out;

  //&:hover {
  //  background-color: var(--main) !important;
  //  color: #fff;
  //  @include gradient-vertical(#ee3333, #ee3333);
  //  @include mediumShadow;
  //}
}

.btnContact {
  @include flex;
  align-items: center;
  flex-direction: column;
  font-family: $Work;
  color: var(--color00);
  font-size: 1em;
  @include borderRadius;

  .icon {
    font-size: 1.2em;
    margin-bottom: 0.45rem;
  }
}

.btnMobile {
  @include flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: $Work;
  color: var(--white);
  @include borderRadius;
  @include softShadow;
  background-color: var(--color00);

  .icon {
    font-size: 1.9em;
    margin-bottom: 0.45rem;
  }
}

// propiedas en defoult
.btnMobileDefault {
  position: relative;
}

// propiedades en active
.btnMobileActive {
  background-color: var(--white);
  color: var(--color00);
  animation: popup 0.25s ease-out;
}

// propiedas en defoult
.btnMobileDefaultDark {
  background-color: var(--main);
  color: var(--color04);
}

// propiedades en active
.btnMobileActiveDark {
  background-color: var(--white);
  color: var(--color00);
}

.btnFacebook {
  @include gradient-vertical(#00C9FF, #006CFF);
  color: #fff;

  &:hover {
    // @include gradient-vertical(#0182be, #006CFF );
  }
}

.btnWhatsapp {
  @include gradient-vertical(#60D66A, #20B038);
  color: var(--color02);

  &:hover {
    @include gradient-vertical(#20B038, #29bb41);
  }
}

.btnGoogle {
  @include gradient-vertical(#ce514d, #c71610);
  color: #fff;

  &:hover {
    // @include gradient-vertical(#ce514d, #b6100a );
  }
}

.btnCall {
  background-color: var(--white);
  color: var(--color00);
}

.btnDefault {
  color: gray;

  .icon {
    color: var(--white);
  }
}

//-----//

.display {
  @include mainCenter;
  color: var(--white);
  background-color: var(--color00);
  border-radius: 2em;
  margin: 0 0.25em;
  text-transform: uppercase;
  box-shadow: 0em 0.1em 0.3em rgba(0, 0, 0, 0.08);

  &:hover {
    color: var(--main);
  }
}

.displayDefault {
  color: var(--color05);
  background-color: var(--color00);
}

.displayActive {
  color: var(--white) !important;
  background-color: var(--color00);
}

//-----//

.displayDark {
  @include mainCenter;
  color: var(--color03);
  border: 1px solid var(--color03);
  border-radius: 2em;
  margin: 0 0.25em;
  text-transform: uppercase;
  @include softShadow;

  &:hover {
    color: var(--color00);
    border-color: var(--color00);
  }
}

.displayDarkDefault {
  color: var(--color03);
  border-color: var(--color03);
}

.displayDarkActive {
  color: var(--color00) !important;
  border-color: var(--color00);
}

//-----//

.filter {
  @include mainCenter;
  border-radius: 2em;
  margin: 0 0.25em;
  min-width: 3.1em;
}

.filterDefault {
  color: var(--main);
  background-color: transparent;
  border: 1px solid var(--main);

  //&:hover {
  //  color: var(--white);
  //  border: 1px solid var(--white);
  //}

}

.filterActive {
  color: var(--color05);
  border: 1px solid var(--white);
  background-color: var(--main);

  //&:hover {
  //  background-color: var(--color05);
  //  border: 1px solid var(--color05);
  //}
}

//---TAB-BUTTON---//

.tab {
  border-bottom: 2px solid transparent;
  margin-bottom: -2px;
  font-weight: 500;
  // color: var(--color05) !important;
}

.tabDefault {
  // color: var(--color10);
  &:hover {
    border-bottom: 2px solid var(--color06);
    // color: var(--color06) !important;
  }
}

.tabActive {
  border-bottom: 2px solid var(--white);
  // color: var(--color01) !important;
}

// Simple
.simple {
  color: var(--main);
  position: relative;
}

.input {
  font-weight: 300;
  color: var(--color06);
  position: relative;
}

.iconSimple {
  color: var(--white);
  background-color: var(--color00);
  @include borderRadius;
}

.simpleBtn {
  color: var(--color10);
  background-color: var(--color03);
  @include borderRadius;
  padding: 0.5em;
  font-size: 0.85em;

  &:hover {
    background-color: var(--color04);
  }
}

// Bubble Header Nav

.bubbleNav {
  @include mainCenter;
  color: var(--white);
  background-color: var(--color03);
  border-radius: 2em;
  font-size: 0.85em;
  padding: $gutter/2 $gutter;
  text-transform: uppercase;

  &:hover {
    color: var(--main);
  }
}

.bubbleNavDefault {
  color: var(--color05);
  // background-color: var(--color00);
}

.bubbleNavActive {
  color: var(--white) !important;
  // background-color: var(--color00);
}

// cubeader Nav

.cube {
  @include mainCenter;
  color: var(--white);
  background-color: var(--color00);
  border-radius: 0.25em;
  @include softShadow;
}

.cubeDark {
  @include mainCenter;
  color: var(--color03);
  border: 1px solid var(--color03);
  border-radius: 0.25em;
  @include softShadow;

  p {
    font-size: 0.75em;
    font-weight: 500;
    letter-spacing: 0.025em;
  }
}

// Bubble Busquedas

.bubbleBusquedas {
  @include mainCenter;
  color: var(--main);
  background-color: var(--color00);
  border-radius: 2em;
  margin-bottom: $gutter;
  padding: $gutter/2 $gutter;
}

.bubbleBusquedasDefault {

}

.bubbleBusquedasActive {

}

// form Buttom
.formNext {
  justify-content: center;
  background-color: var(--color00);
  color: var(--white);
  align-items: center;
  @include borderRadius;
  @include softShadow;
  font-family: $Work;
  font-weight: 500;
  letter-spacing: 0.025em;
  text-align: left;

  .icon {
    align-self: flex-start;
    font-size: 1.25em;
  }

  &:hover {
    @include mediumShadow;
  }

  .defaultPre {
    margin-right: 0.3em;
  }

  .default {
    margin-left: 0.3em;
  }
}

// propiedas en defoult
.formNextDefault {
  background-color: var(--color04);
  color: var(--color03);
}

// propiedades en active
.formNextActive {
  background-color: var(--color00);
  color: var(--white);
}

.formSend {
  justify-content: center;
  background-color: var(--white);
  color: var(--color00);
  @include borderRadius;
  @include softShadow;
  font-family: $Work;
  font-weight: 500;
  letter-spacing: 0.025em;
  min-width: 8em;

  &:hover {
    @include mediumShadow;
  }
}

.red {
  color: var(--main)
}

.topNav {
  text-transform: uppercase;
  font-weight: 500;
  color: var(--color13);
  font-size: 0.85em;
}
</style>
