import Vue from 'vue'
import Vuex from 'vuex'

import { getProperties, getDevelopments } from '@/api'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    searchKey: '',
    filter: {
      item: 0,
      order: '',
      zones: [],
      propTypes: [],
      scope: [null, null],
      view: true,
      operation: ''
    },
    zones: [
      {
        name: 'Todas',
        key: 0,
        state: true
      }
    ],
    types: [
      {
        name: 'Todas',
        key: 0,
        state: true
      }
    ],
    properties: [],
    developments: []
  },
  getters: {
    isZonesLoaded: state => {
      return (state.zones.length > 1)
    },
    properties: state => {
      let filteredProperties
      const filZon = state.filter.zones
      const filKey = state.searchKey
      const filTypes = state.filter.propTypes
      const filScope = state.filter.scope
      const order = state.filter.order
      const hasScope = filScope[0] !== null || filScope[1] !== null
      const hasOperation = !!state.filter.operation
      const noFilters = !filZon.length && !filKey && !filTypes.length && !order && !hasScope && !hasOperation

      if (noFilters) return state.properties

      filteredProperties = [...state.properties]
      if (filKey) {
        const key = filKey.toLowerCase()

        filteredProperties = filteredProperties.filter((p) => {
          return p.location.full.toLowerCase().includes(key) ||
            p.location.name.toLowerCase().includes(key) ||
            p.type.name.toLowerCase().includes(key)
        })
      }

      if (filZon.length) {
        filteredProperties = filteredProperties.filter((p) => filZon.includes(p.location.key))
      }

      if (filTypes.length) {
        filteredProperties = filteredProperties.filter((p) => filTypes.includes(p.type.key))
      }

      if (hasScope) {
        if (filScope[0] > filScope[1]) filScope.reverse()
        if (filScope[0]) {
          filteredProperties = filteredProperties
            .filter((p) => p.operations[0].prices[0].price > filScope[0])
        }

        if (filScope[1]) {
          filteredProperties = filteredProperties
            .filter((p) => p.operations[0].prices[0].price < filScope[1])
        }
      }

      if (hasOperation) {
        filteredProperties = filteredProperties
          .filter((p) => p.operations.flatMap((o) => o.type).includes(state.filter.operation))
      }

      if (order) {
        filteredProperties.sort((a, b) => {
          if (!a.operations[0].prices[0].price) return -1
          return a.operations[0].prices[0].price - b.operations[0].prices[0].price
        })
        if (order === 'des') filteredProperties.reverse()
      }

      return filteredProperties
    },
    allProperties: state => {
      return state.properties
    },
    property (state) {
      return (id) => state.properties.find((x) => x.id === id)
    },
    developments (state) {
      return state.developments
    },
    propertiesInDev (state) {
      return (id) => {
        return state.properties.filter((x) => id === x.development_id)
      }
    },
    development (state, getters) {
      return (id) => {
        const development = { ...getters.developments.find((x) => x.id === id) }
        development.properties = getters.propertiesInDev(development.id_rel)
        development.units = development.properties.length
        return development
      }
    },
    filter (state) {
      return state.filter
    },
    types (state) {
      return state.types
    },
    zones (state) {
      return state.zones
    }
  },
  actions: {
    async getAllProperties ({ commit }) {
      await getProperties((prop) => {
        commit('setProperties', prop)
        commit('SET_ZONES', prop)
        commit('SET_TYPES', prop)
      })
    },
    async getAllDevelopments ({ commit }) {
      await getDevelopments((dev) => {
        commit('setDevelopments', dev)
      })
    },
    setSearch ({ commit }, payload) {
      return new Promise(resolve => {
        commit('SET_SEARCH', payload)
        resolve('ok')
      })
    },
    setItemProps ({ commit }, payload) {
      return new Promise(resolve => {
        commit('SET_ITEM_PROPS', payload)
        resolve()
      })
    },
    searchHasZone (state) {
      const dep = (text) =>
        text
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()

      return dep(state.state.searchKey)
        .split(' ')
        .filter(k => k.length > 3)
        .map(k =>
          state.state.zones.find(
            z =>
              dep(z.name)
                .split(' ')
                .includes(k) || false
          )
        )
    },
    setFilter ({ commit }, payload) {
      return new Promise(resolve => {
        commit('SET_FILTER', payload)
        resolve('ok')
      })
    },
    resetLists ({ commit }) {
      return new Promise(resolve => {
        commit('RESET_LISTS')
        resolve('ok')
      })
    }
  },
  mutations: {
    setProperties (state, properties) {
      state.properties = properties
    },
    setDevelopments (state, developments) {
      state.developments = developments
    },
    SET_ZONES (state, properties) {
      const allZones = [...state.zones]
      properties.forEach((property) => {
        const location = {
          key: property.location.key,
          name: property.location.name,
          state: false
        }

        const foundZone = allZones.find((z) => z.key === location.key)
       
        if (!foundZone) {
          allZones.unshift(location)
        }

        state.zones = allZones
      })
    },
    SET_TYPES (state, properties) {
      const allTypes = [...state.types]
      properties.forEach((property) => {
        const type = {
          key: property.type.key,
          name: property.type.name,
          state: false
        }

        const foundTypes = allTypes.find((t) => t.key === type.key)
       
        if (!foundTypes) {
          allTypes.unshift(type)
        }

        state.types = allTypes
      })
    },
    SET_SEARCH (state, key) {
      state.searchKey = key
    },
    SET_ITEM_PROPS (state, item) {
      state.filter.item = item
    },
    SET_FILTER (state, filter) {
      state.filter = filter
    },
    RESET_LISTS (state) {
      state.zones.map(z => {
        if (z.key) z.state = false
      })
      state.types.map(t => {
        if (t.key) t.state = false
      })
    }
  }
})

export default store
