<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      :fill="fillColor"
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M18.955,17.216l-4.5-4.679a7.628,7.628,0,1,0-5.841,2.725,7.55,7.55,0,0,0,4.372-1.381L17.521,18.6a1,1,0,1,0,1.435-1.38ZM8.616,1.991A5.641,5.641,0,1,1,2.975,7.632,5.647,5.647,0,0,1,8.616,1.991Z"
          transform="translate(1.595 2.6)"
          :fill="fillColor"
        />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    fillColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
