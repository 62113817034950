<template>
  <div>
    <div :class="['navHeader', !show]">
      <Button
        :class="['tasa', isTasa]"
        model="btnLineWPlus btnLineWActive"
        size="m"
        @click.native="goTo('/valuation')"
      >
        Tasá tu propiedad
      </Button>
      <!--      <Button-->
      <!--        model="menuItemHeader menuItemHeaderFav"-->
      <!--        :class="aniFav"-->
      <!--        size="lFlat"-->
      <!--        swich-->
      <!--        @click.native="swichWindow('FavCard')">-->
      <!--        <div slot="defaultPre">{{ nFavs }}<LikeIcon /></div>-->
      <!--        <p>Favoritos</p>-->
      <!--      </Button>-->
      <Button
        class="consultar"
        model="menuItemHeader"
        size="lFlat"
        swich
        @click.native="sendWhatsapp()"
      >
        <WhatsappIcon
          slot="defaultPre"
          fill-color="var(--color01)"
        />
        <p>Consultar</p>
      </Button>
      <Button
        v-show="header"
        size="l"
        model="menuItemHeader"
        name="hamburguer"
        @click.native="showMenu"
      >
        <span class="menuHamburguer">
          <MenuIcon fill-color="var(--color01)" />
          <p>Menu</p>
        </span>
      </Button>
      <Button
        v-show="!header"
        size="l"
        model="menuItemHeader"
        name="hamburguer"
        @click.native="showMenu"
      >
        <span class="menuHamburguer">
          <CloseIcon fill-color="var(--color01)" />
          <p>Menu</p>
        </span>
      </Button>
    </div>
  </div>
</template>

<script>

import Bus from '@/utils/Bus'
import WhatsappIcon from '@/icons/Whatsapp'
import MenuIcon from '@/icons/Menu'
// import LikeIcon from '@/icons/Like'
import CloseIcon from '@/icons/Close'
import Button from '@/build/Button'

export default {
  name: 'NavHeader',
  components: {
    Button,
    WhatsappIcon,
    MenuIcon,
    CloseIcon
    // LikeIcon
  },
  props: {
    header: Boolean
  },
  data () {
    return {
      show: true,
      showHeader: false,
      aniFav: ''
    }
  },
  computed: {
    // nFavs () {
    //   return this.$store.getters.favorites.length
    // },
    contactWhatsapp () {
      const phoneNumber = '5491158430937'
      const message = 'Consulta desde la web.'
      return 'https://wa.me/' + phoneNumber + '?text=' + encodeURIComponent(message)
    },
    isTasa () {
      if (this.$route.name === 'Valuation') return 'hide'
      return ''
    }
  },
  mounted () {
    Bus.$on('showMenu', this.openMenu)
  },
  methods: {
    showMenu () {
      Bus.$emit('showMenu')
      // this.openMenu()
    },
    swichWindow (type) {
      Bus.$emit('swichWindow', type)
    },
    goTo (route) {
      // this.$router.push(route)
      if (!this.isMobile) {
        this.$router.push(route)
      } else {
        const routeData = this.$router.resolve({ path: `${route}` })
        window.open(routeData.href, '_blank')
      }
    },
    openMenu () {
      this.show = !this.show
    },
    sendWhatsapp () {
      window.open(this.contactWhatsapp)
    }

  }
  // watch: {
  //   nFavs (n, o) {
  //     this.aniFav = 'popUp'
  //     setTimeout(() => {
  //       this.aniFav = ''
  //     }, 100)
  //   }
  // }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/global";

#app.home.block header.headerArea div.headerContainer .navHeader,
#app.developments.block header.headerArea div.headerContainer .navHeader,
#app.common.block header.headerArea div.headerContainer .navHeader{
  opacity: 0;
}
.navHeader{
  @include crossCenter;
  color: var(--white);
  font-family: $Work;
}

.menuHamburguer{
  @include flex;
  align-items: flex-start;
  flex-direction: column;
}
.special{
  display: none;
  @include from(l) {
    display: flex;
  }
}
.tasa{
  margin-right: 0.5em;
  margin-bottom: 0.25em;
  padding-right: 0.8em;
  padding-left: 0.8em;
  font-size: 0.8em;
  background-color: var(--main);
  .icon{
    font-size: 1.5em;
  }
  @include from(l) {
    font-size: 1.1em;
  }
}
.searchNav{
  max-width: 24em;
}

.dsk{
  display: flex;
  @include to(m) {
    display: none;
  }
}
.mob{
  display: flex;
  @include from(m) {
    display: none;
  }
}
.none{
  display: none;
  margin-top: -100%;
}
.menuItemHeaderFav{
  @include mainCenter;
  align-items: center;
  div{
    background-color: var(--white);
    @include mainCenter;
    color: var(--color00);
    padding: 0.04em 0.4em;
    border-radius: 0.5em;
    font-size: 0.75em;
    font-family: $Work;
    .icon{
      padding-left:0.1em;
    }
  }
}
.consultar{
  display: none;
  @include from(m) {
    display: flex;
  }
}

.developments, .development {
  .menuItemHeader{
    color: var(--color00) !important
  }
}

.hide {
  display: none;
}

p {
  color: var(--color00);
}

</style>
