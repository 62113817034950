<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M-377.549,21.127h-1.038a1.323,1.323,0,0,0,.081-.442V5.944a1.342,1.342,0,0,0-1.341-1.34h-12.522a1.342,1.342,0,0,0-1.341,1.34v14.74a1.323,1.323,0,0,0,.08.442h-.976a.447.447,0,0,0-.447.447.447.447,0,0,0,.447.446h2.159c.026,0,.051.007.077.007h12.522c.04,0,.078-.008.118-.012.01,0,.019.006.03.006h2.15a.447.447,0,0,0,.447-.446A.447.447,0,0,0-377.549,21.127Zm-10.174,0V18.266h3.23v2.857Zm3.379-3.75h-3.528a.746.746,0,0,0-.745.745v3.015h-3.751a.449.449,0,0,1-.448-.448V14.667H-379.4v6.017a.449.449,0,0,1-.448.448H-383.6V18.118A.745.745,0,0,0-384.344,17.373Zm.476-7.267v3.668h-4.058V10.106Zm-4.058-.893V5.5h4.058V9.213Zm8.527,4.561h-3.575V10.106h3.575Zm-9.42,0h-4V10.106h4Zm9.42-7.83V9.213h-3.575V5.5h3.127A.448.448,0,0,1-379.4,5.944ZM-392.369,5.5h3.549V9.213h-4V5.944A.448.448,0,0,1-392.369,5.5Z"
          transform="translate(398.026 -1.66)"
          :fill="fillColor"
        />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    fillColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
