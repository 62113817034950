<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <g transform="translate(-637.831 -3042.693)">
          <path
            d="M654.7,3054.644a3.1,3.1,0,0,0-1.061-.458,2.2,2.2,0,0,1-.822-.338.448.448,0,0,1-.17-.364.455.455,0,0,1,.212-.372,1.116,1.116,0,0,1,.688-.186.958.958,0,0,1,.666.224.676.676,0,0,1,.237.5v.031a.31.31,0,0,0,.619,0v-.032a1.246,1.246,0,0,0-.433-.976,1.509,1.509,0,0,0-.794-.356v-.487a.331.331,0,0,0-.661,0v.512a1.68,1.68,0,0,0-.7.256,1.048,1.048,0,0,0-.465.894,1.013,1.013,0,0,0,.407.862,3.187,3.187,0,0,0,1.053.446,2.451,2.451,0,0,1,.84.354.5.5,0,0,1-.049.8,1.182,1.182,0,0,1-.7.19,1.45,1.45,0,0,1-.771-.2.574.574,0,0,1-.271-.492l0-.067a.309.309,0,0,0-.619,0v.041a1.148,1.148,0,0,0,.52,1.042,1.935,1.935,0,0,0,.755.264v.513a.331.331,0,1,0,.661,0v-.5a1.738,1.738,0,0,0,.8-.274,1.077,1.077,0,0,0,.48-.926A1.062,1.062,0,0,0,654.7,3054.644Z"
            transform="translate(-3.042 -1.583)"
            :fill="fillColor"
          />
          <path
            d="M661.206,3057.978a2.109,2.109,0,0,0-2.786-.849l-1.823.861v-5.744l1.128.869a.628.628,0,0,0,.83.041l.786-.629a.63.63,0,0,0,.078-.908.459.459,0,0,0-.048-.045l-8.24-6.627a.892.892,0,0,0-1.244,0L647.331,3047v-.359a.865.865,0,0,0-.865-.865h-1.174a.865.865,0,0,0-.865.866l0,2.69-2.783,2.238a.551.551,0,0,0-.048.045.628.628,0,0,0,.077.906l.787.631a.628.628,0,0,0,.83-.041l1.128-.869v3.987a5.3,5.3,0,0,0-1.266.449l.019-.7a.417.417,0,0,0-.406-.429l-3.311-.092a.418.418,0,0,0-.429.406l-.2,7.268a.418.418,0,0,0,.406.428l3.311.091h.012a.417.417,0,0,0,.417-.406l.01-.348.86-.46a1.477,1.477,0,0,1,1.1-.121l5.137,1.441.027.007a5.568,5.568,0,0,0,3.457-.391.47.47,0,0,0,.051-.028l7.448-4.818A.418.418,0,0,0,661.206,3057.978Zm-19.053,4.835-2.476-.068.177-6.433,2.477.068Zm12.748-4.482a2.642,2.642,0,0,0-1.957-1.047c-.023,0-.044-.01-.066-.011l-.658-.018-2.361-.065a3.163,3.163,0,0,1-1.227-.3c-.048-.021-.1-.031-.146-.055l-.307-.16c-.089-.046-.181-.08-.272-.122a5.4,5.4,0,0,0-2.292-.489c-.137,0-.273.021-.41.034v-4.456l5.306-4.086,5.307,4.087v6.38a.323.323,0,0,1-.322.323h-.582Zm-9.692-11.771h1.257a.085.085,0,0,1,.084.084v.963c0,.007,0,.013,0,.021l-1.344,1.081,0-2.064Zm-2.353,5.909-.538-.431,8.082-6.5c.017-.015.033-.03.048-.045a.073.073,0,0,1,.127,0c.015.015.031.031.048.045l8.082,6.5-.537.431-1.712-1.319-5.7-4.394a.392.392,0,0,0-.477,0l-5.72,4.406Zm10.337,10.167a4.733,4.733,0,0,1-2.9.323l-5.123-1.438a2.311,2.311,0,0,0-1.721.189l-.44.235.118-4.276a4.545,4.545,0,0,1,1.292-.592c.153-.043.309-.078.466-.106.1-.018.21-.017.315-.027.137-.012.272-.033.41-.036a4.6,4.6,0,0,1,2.179.511l.113.058.195.1a4.277,4.277,0,0,0,1.734.448l2.384.065.635.018c.03,0,.059.008.089.01a1.806,1.806,0,0,1,.725.212c.008,0,.015.012.023.017a1.818,1.818,0,0,1,.709.708c.013.023.022.048.035.073a1.8,1.8,0,0,1,.16.44l-4.5-.125a.418.418,0,0,0-.023.835l4.964.137h.011a.417.417,0,0,0,.417-.406,2.669,2.669,0,0,0-.04-.542l.728-.345.154-.073.9-.427.374-.177,1.2-.564.01,0a1.276,1.276,0,0,1,1.443.2Z"
            transform="translate(0 0)"
            :fill="fillColor"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    fillColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
