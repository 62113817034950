<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      :stroke="stroke"
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="#111111"><g transform="translate(-.746 -.748)">
        <path
          class="st0"
          d="M20.1 12.2l-7.3-5.7-7.4 5.7"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
        />
        <path
          class="st0"
          d="M5.4 12.2l-2.3 1.7c-.1.1-.3.1-.4 0l-1-.8c-.1-.1-.2-.3 0-.4l10.6-8.6c.2-.3.6-.3.9-.1l.1.1 10.6 8.6c.1.1.1.3 0 .4l-1 .8c-.1.1-.3.1-.4 0l-2.3-1.8"
          fill="none"
          stroke-width="1.1"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
        />
        <circle
          class="st0"
          cx="12.7"
          cy="11.7"
          r="2.1"
          fill="none"
          stroke-width="1.1"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
        /><path
          class="st1"
          d="M5.4 12.2v8.4c0 .5.4.9.9.9h12.8c.5 0 .9-.4.9-.9v-8.5"
          fill="none"
          stroke-width="1.1"
          stroke-miterlimit="10"
        /><path
          class="st0"
          d="M21.5 21.5h-2.2"
          fill="none"
          stroke-width="1.1"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
        /><path
          class="st0"
          d="M6.2 21.5H4"
          fill="none"
          stroke-width="1.1"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
        /><path
          class="st1"
          d="M15.3 16h-5.1c-.2 0-.4.2-.4.4v5h6v-5c-.1-.2-.3-.4-.5-.4z"
          fill="none"
          stroke-width="1.1"
          stroke-miterlimit="10"
        /><path
          class="st2"
          d="M8.1 7.2V6c0-.3-.3-.6-.6-.6H6c-.3 0-.6.2-.6.6v3.6"
          fill="none"
          stroke-width="1.1"
          stroke-linecap="round"
          stroke-miterlimit="10"
        /></g></g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    stroke: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
