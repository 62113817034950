<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      :fill="fillColor"
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M12,23.2c-2,0-8.4-9.2-8.6-14.4C3.3,7,4.1,5.1,5.5,3.6C7.2,1.8,9.6,0.8,12,0.8c2.4,0,4.8,1,6.5,2.8c1.5,1.5,2.2,3.4,2.1,5.3C20.4,13.5,14.2,23.2,12,23.2z M12,2.3c-2,0-3.9,0.8-5.3,2.3C5.5,5.8,4.8,7.3,4.9,8.9C5,11,6.2,14.1,8.3,17.2c0.7,1.2,1.6,2.3,2.5,3.4c0.3,0.4,0.6,0.7,1,1l0.2,0.1l1.1-1.1c0.9-1.1,1.8-2.2,2.6-3.4c2-3,3.3-6.2,3.4-8.3c0-1.5-0.6-3.1-1.8-4.3C15.9,3.1,14,2.3,12,2.3z M12,12.7c-1.9,0-3.4-1.5-3.4-3.4C8.6,7.5,10.1,6,12,6s3.4,1.5,3.4,3.4C15.3,11.2,13.9,12.7,12,12.7z M12,7.5c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.8,1.9,1.9c1,0,1.9-0.8,1.9-1.9S13,7.5,12,7.5z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Ubicación'
    },
    fillColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
