<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      :stroke="strokeColor"
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="user"
        clip-path="url(#clip-user)"
      >
        <g
          id="Grupo_869"
          data-name="Grupo 869"
          transform="translate(-1654.148 -2460.585)"
        >
          <circle
            id="Elipse_142"
            data-name="Elipse 142"
            cx="2.639"
            cy="2.639"
            r="2.639"
            transform="translate(1663.617 2466.19)"
            stroke-width="1.15"
            stroke="#e00009"
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
          <circle
            id="Elipse_143"
            data-name="Elipse 143"
            cx="9.508"
            cy="9.508"
            r="9.508"
            transform="translate(1656.748 2463.185)"
            stroke-width="1.15"
            stroke="#e00009"
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
          <path
            id="Trazado_882"
            data-name="Trazado 882"
            d="M1677.037,2491.577a5.276,5.276,0,0,0-10.551,0l-.092,2.147s1.265,1.717,5.152,1.717a9.522,9.522,0,0,0,5.581-1.717Z"
            transform="translate(-5.505 -13.232)"
            fill="none"
            stroke="#e00009"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.15"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    strokeColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
