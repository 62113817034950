<template>
  <img
    src="/sauret-logo-horizontal.svg"
    alt=""
  >
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    fillColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/scss/global";

.icon {
  display: flex;
  align-self: flex-start;
  // position: relative;
  //@debug height: 10%;
  // width: 2em;
  //font-size: 2.5em;
  @include from(l) {
    //font-size: 4.2em;
  }
}

.icon_svg {
  // height: 1em;
  // width: 2em;
  // position: absolute;
}
</style>
