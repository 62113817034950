<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      :fill="fillColor"
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <rect
          x="0.08"
          y="4.44"
          width="23.84"
          height="2.11"
          rx="1.05"
        />
        <rect
          x="0.08"
          y="10.95"
          width="23.84"
          height="2.11"
          rx="1.05"
        />
        <rect
          x="0.08"
          y="17.45"
          width="23.84"
          height="2.11"
          rx="1.05"
        />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    fillColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
