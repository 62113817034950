import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import Sticky from "vue-sticky-directive";
import * as VueGoogleMaps from "vue2-google-maps";
import LiveMove from "./LiveMove";
import VueMeta from "vue-meta";
Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(Sticky);
Vue.use(LiveMove);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY, // !!KEY
    libraries: "places",
    installComponents: true,
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
