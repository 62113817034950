<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      :stroke="strokeColor"
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <g transform="translate(-1396.898 -1628.297)">
          <path
            d="M1399.9,1652.667v9.282a1.236,1.236,0,0,0,1.236,1.236h15.451a1.236,1.236,0,0,0,1.236-1.236v-9.282"
            transform="translate(0 -14.766)"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.15"
          />
          <path
            d="M1457.9,1652.667"
            transform="translate(-40.077 -14.766)"
            fill="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.15"
          />
          <path
            d="M1403.452,1647.44a16.528,16.528,0,0,0-3.554,1.565h0l8.26,5.695a1.235,1.235,0,0,0,1.4,0l8.26-5.695h0a16.517,16.517,0,0,0-3.553-1.565"
            transform="translate(0 -11.154)"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.15"
          />
          <path
            d="M1411.4,1640.3v-8.078a.927.927,0,0,1,.927-.927h8.961a.925.925,0,0,1,.927.925v8.079l-4.706,3.245a1.236,1.236,0,0,1-1.4,0l-4.705-3.244"
            transform="translate(-7.947)"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.15"
          />
          <g transform="translate(1405.924 1634.044)">
            <line
              x1="5.871"
              transform="translate(0 0)"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.15"
            />
            <line
              x1="2.936"
              transform="translate(0 4.764)"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.15"
            />
            <line
              x1="5.871"
              transform="translate(0 2.382)"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.15"
            />
          </g>
          <line
            x1="6.456"
            y2="5.502"
            transform="translate(1400.296 1642.582)"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.15"
          />
          <line
            x2="6.472"
            y2="5.502"
            transform="translate(1410.952 1642.582)"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.15"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    strokeColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
