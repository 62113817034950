<template>
  <header
    ref="header"
    :class="['headerArea', none]"
  >
    <div class="headerContainer">
      <SauretIcon
        class="logo"
        title="Home"
        @click.native="goTo('')"
      />
      <!-- BUSCADOR -->
      <div class="buscad dsk">
        <Button
          v-if="homeClass"
          :class="['mapMode', 'dsk']"
          model="cube"
          size="l"
          @click.native="toggleView"
        >
          <PlaceholderIcon
            slot="default"
            title="Ver en mapa"
          />
        </Button>
        <Search :class="['searchNav', searchNav]" />
      </div>
      <NavHeader header />
    </div>
    <!-- <code><pre>{{ $data }}</pre></code> -->
  </header>
</template>
<script>
import Bus from '@/utils/Bus'
import SauretIcon from '@/icons/Sauret'
import Button from '@/build/Button'
import Search from '@/standard/Search'
import PlaceholderIcon from '@/icons/Placeholder'
import NavHeader from '@/widgets/NavHeader'

export default {
  name: 'Header',
  components: {
    Button,
    SauretIcon,
    Search,
    NavHeader,
    PlaceholderIcon
  },
  props: {
  },
  data() {
    return {
      show: false,
      showHeader: false,
      aniFav: ''
    }
  },
  computed: {
    homeClass() {
      return this.$route.name === 'Home'
    },
    filter() {
      return this.$store.getters.filter
    },
    fixed() {
      return this.show ? 'fixed' : ''
    },
    nFavs() {
      return this.$store.getters.favorites.length
    },
    none() {
      // console.log(this.$route.name);
      if (this.$route.path === '/') return 'home'
      if (this.$route.path === '/valuation') return 'home'
      if (this.$route.path === '/faq') return 'home'
      // if (this.$route.name == 'Property') return 'dsk'
      if (this.$route.name === 'Development') return 'development dsk'
      if (this.$route.name === 'Developments') return 'developments'
      if (this.$route.name === 'DevelopmentsFilter') return 'developments'
      if (this.$route.name === 'Property') return 'dsk'
      return 'common'
    },
    searchNav() {
      if (this.$route.name === 'Property') return 'none'
      if (this.$route.name === 'Properties') return 'dsk'
      if (this.$route.name === 'PropertiesFilter') return 'dsk'
      if (this.$route.path === '/') return 'dsk'

      return 'none'
    },
    contactWhatsapp() {
      const phoneNumber = '5491140058000'
      const message = 'Consulta desde la web.'
      return 'https://wa.me/' + phoneNumber + '?text=' + encodeURIComponent(message)
    }
  },
  mounted() {
    Bus.$on('showMenu', this.openMenu)
  },
  methods: {
    swichWindowF() {
      this.$router.push('/properties')
    },
    toggleView() {
      this.filter.view = !this.filter.view
      this.$store.dispatch('setFilter', { ...this.filter })
      this.swichWindowF()
    },
    showMenu() {
      Bus.$emit('showMenu')
    },
    swichWindow(type) {
      Bus.$emit('swichWindow', type)
    },
    goTo(site) {
      this.$router.push(`/${site}`)
    },
    openMenu() {
      this.show = !this.show
    },
    sendWhatsapp() {
      window.open(this.contactWhatsapp)
    }
  }
  // watch: {
  //   nFavs (n, o) {
  //     this.aniFav = 'popUp'
  //     setTimeout(() => {
  //       this.aniFav = ''
  //     }, 100)
  //   }
  // }
}
</script>
<style lang="scss" scoped>
@import "src/assets/scss/global";

.headerArea {
  overflow: hidden;
  @include mainDistribute;
  width: 100%;
  // Original
  // @include gradient-vertical(#414946, rgba(242, 242, 242, 0), $start-percent: 75%, $end-percent: 100%);
  // Blanco
  @include gradient-vertical(#fff, rgba(242, 242, 242, 0), $start-percent: 75%, $end-percent: 100%);
  z-index: 80;
  margin-top: 0;
  transition: all .25s;

  &.home {
    top: 0;
    position: fixed;
  }

  &.common {
    position: relative;
  }
}

.headerContainer {
  @include edContainer;
  @include edItem;
  @include mainJustify;
  padding: 0 0 1em;
  align-items: center;
  position: relative;
  height: 6em;

  @include fromTo(s, l) {
    height: 4em;
  }
}

.buscad {
  min-width: 30%;
  display: flex;
}

.logo {
  cursor: pointer;
  margin: $gutter/1.5 $gutter;
  width: 6em;
  color: var(--white);

  @include from(l) {
    width: 12.5em;
  }
}

.searchNav {
  max-width: 24em;
}

.dsk {
  display: flex;

  @include to(l) {
    display: none;
  }
}

.mob {
  display: flex;

  @include from(l) {
    display: none;
  }
}

.none {
  display: none;
  margin-top: -100%;
}

.headerArea {
  &.developments {
    @include gradient-vertical(#ffffff, rgba(42, 46, 67, 0), $start-percent: 85%, $end-percent: 100%);

    & .menuItemHeaderFav {
      color: var(--color03);
    }

    & .logo {
      color: var(--color03);
    }

    & .menuHamburguer {
      color: var(--color03);
    }

    & .consultar {
      color: var(--color03);
    }
  }

  &.development {
    @include gradient-vertical(#ffffff, rgba(42, 46, 67, 0), $start-percent: 85%, $end-percent: 100%);

    & .menuItemHeaderFav {
      color: var(--color03);
    }

    & .logo {
      color: var(--color03);
    }

    & .menuHamburguer {
      color: var(--color03);
    }

    & .consultar {
      color: var(--color03);

    }
  }
}
</style>
