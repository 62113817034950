/* eslint-disable */
import { createClient } from "contentful";

const client = createClient({
  space: process.env.VUE_APP_SPACE,
  accessToken: process.env.VUE_APP_ACCESS_TOKEN,
  removeUnresolved: true,
});

export const getProperties = async (cb) => {
  const data = await client.getEntries({
    content_type: "propiedad",
    include: 8,
    order: "-fields.weight",
  });

  const props = data.items.map((d) => {
    return {
      ...format(d),
      sold: d.fields.vendido,
      operations: d.fields.operations,
      parkings: d.fields.parkings,
      age: d.fields.age,
      bathrooms: d.fields.bathrooms,
      suites: d.fields.suites,
      toilet: d.fields.toilet,
      rooms: d.fields.rooms,
      total_surface: d.fields.total_surface,
      roofed_surface: d.fields.roofed_surface,
    };
  });
  formatOperations(props);
  cb(props);
};

export const getDevelopments = async (cb) => {
  const data = await client.getEntries({
    content_type: "development",
    include: 8,
  });
  const developments = data.items.map((d) => format(d));
  cb(developments);
};

/* eslint-disable */
export function formatOperations(props) {
  props.forEach((p) =>
    p.operations.map((o) =>
      Object.assign(o, {
        type: o.fields.type,
        prices: o.fields.prices,
      })
    )
  );
  props.forEach((p) =>
    p.operations.forEach((o) => {
      o.prices = o.prices.map((price) => price.fields);
    })
  );
}

export function getTags(d) {
  return {
    1: d.fields.services?.map((s) => s.fields).filter((e) => e.type === 1),
    2: d.fields.services?.map((s) => s.fields).filter((e) => e.type === 2),
    3: d.fields.services?.map((s) => s.fields).filter((e) => e.type === 3),
  };
}

function getVideos(d) {
  return d.fields?.videos?.map((v, id) => {
    let video_id;

    if (v.includes("shorts")) {
      video_id = v.split("/").pop();
    } else {
      video_id = v.split("=", 2).pop();
    }
    
    return {
      video_id,
      id,
    };
  });
}

export function format(d) {
  return {
    id: d.fields.id,
    status: d.fields.status,
    location: {
      full:
        d.fields.localidad +
        " | " +
        d.fields.localidad +
        " | " +
        d.fields.localidad,
      name: d.fields.location.fields.name,
      key: d.fields.location.fields.id,
    },
    title: d.fields.title,
    address: d.fields.address,
    description: d.fields.description,
    name: d.fields.title,
    photos: d.fields?.photos?.map((p, index) => ({
      url: p.fields.file.url,
      thumb: p.fields.file.url,
      order: index,
      main: index === 0,
    })),
    tags: getTags(d),
    type: {
      key: d.fields.type.fields.id,
      name: d.fields.type.fields.name,
    },
    youtube: getVideos(d),
    // point: d.fields.point
    point: d.fields.point
      ? {
          lng: d.fields.point.lon,
          lat: d.fields.point.lat,
        }
      : null,
    kuula: d.fields.kuula,
  };
}
