<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      :stroke="strokeColor"
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <g transform="translate(-2383.898 -363.297)">
          <rect
            width="16.666"
            height="17.87"
            rx="2"
            transform="translate(2387.898 366.297)"
            stroke-width="1.15"
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
          <path
            d="M2,0H3.066a0,0,0,0,1,0,0V17.87a0,0,0,0,1,0,0H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
            transform="translate(2387.898 366.297)"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.15"
          />
          <rect
            width="5.805"
            height="3.225"
            rx="1"
            transform="translate(2395.314 370.024)"
            stroke-width="1.15"
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    strokeColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
