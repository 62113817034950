<template>
  <div class="searchArea">
    <input
      v-model="value"
      class="search"
      :placeholder="home ? placeholder : message"
      @keyup.enter="send"
      @keyup.delete="del"
    >
    <Button
      class="go"
      model="display"
      size="l"
      title="Buscar"
      @click.native="send"
    >
      <iconSearch title="Buscar" />
    </Button>
    <!-- <pre>{{$data}}</pre> -->
  </div>
</template>

<script>

import Bus from '@/utils/Bus'
import Button from '@/build/Button'
import iconSearch from '@/icons/Search'

export default {
  name: 'Search',
  components: {
    Button,
    iconSearch
  },
  data () {
    return {
      value: '',
      placeholder: '',
      message: '¿Dónde estas buscando?',
      speed: 95,
      count: 0
    }
  },
  computed: {
    filterStore () {
      return this.$store.getters.filter
    },
    preVal () {
      return this.$store.getters.searchKey
    },
    home () {
      return this.$route.name === 'Home'
    }
  },
  watch: {
    preVal (n) {
      setTimeout(() => {
        this.value = n
      }, 20)
    }
  },
  mounted () {
    setTimeout(() => {
      this.value = this.preVal
    }, 20)
    setTimeout(() => {
      this.typeWriter()
    }, 1200)
    if (this.$route.name === 'Home') {
      this.$store.dispatch('setSearch', '')
    }
  },
  methods: {
    send (action) {
      this.$store.dispatch('setSearch', this.value)
      this.$store.dispatch('setItemProps', 1)
      window.scrollTo(0, 0)
      setTimeout(() => {
        Bus.$emit('reloadlistProperties')
      }, 20)
      if (action === 'del') return
      if (this.$route.name !== 'Properties' && this.$route.name !== 'PropertiesFilter') {
        this.$router.push('/properties')
      }
    },
    del () {
      const defFilter = this.filterStore
      if (!this.value) {
        // if (this.$store.getters.searchHasType.length || this.$store.getters.searchHasZone.length) {
        //   defFilter.propTypes = []
        //   defFilter.zones = []
        //   this.$store.dispatch('setFilter', { ...defFilter })
        //   this.$store.dispatch('resetLists')
        // }
        this.send('del')
      }
    },
    typeWriter () {
      if (this.count < this.message.length) {
        this.placeholder += this.message.charAt(this.count)
        this.count++
        setTimeout(() => {
          this.typeWriter()
        }, this.speed)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/global";

.searchArea{
  width: 100%;
  // max-width: 28em;
  @include mainCenter;
  justify-content: flex-end;
}

.search{
  border-radius: 0.35em;
  // max-width: 20em;
  width: calc(100% - 5.5em);
  padding: 0.65em;
  font-size: 1em;
  color: var(--main);
  background-color: white;
  margin-right: 0.75em;
  @include softShadow;
}
input:focus{
  border: none;
}

.go{
  margin: 0;
  border-radius: 0.25em;
}

</style>
