<template>
  <span
    :title="title"
    class="icon"
  >
    <svg
      :fill="fillColor"
      class="icon__svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        x="1.3"
        y="10.8"
        width="21.4"
        height="2.39"
        rx="1.08"
        transform="translate(-4.94 11) rotate(-41.62)"
      />
      <rect
        x="1.3"
        y="10.8"
        width="21.4"
        height="2.39"
        rx="1.08"
        transform="translate(13 28.94) rotate(-138.38)"
      />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    fillColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
